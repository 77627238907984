import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import "../Styles/BookingHistory.css";
import rectangle from "../Images/calico.png";
// import { Navbar2 } from "./NavbarTwo";
// import { Link } from "react-router-dom";
import { Navbar4 } from "./NavbarFour";
import placeholder from "../Images/default.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NewNavbar } from "./NewNavbar";

// const [windowWidth, setWindowWidth] = useState(window.innerWidth);
// const today = new Date();
// const displayedPastReservations = pastReservations.slice(0, 5);
// useEffect(() => {
//   const handleResize = () => {
//     setWindowWidth(window.innerWidth);
//   };

//   window.addEventListener("resize", handleResize);

//   return () => {
//     window.removeEventListener("resize", handleResize);
//   };
// }, []);

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [filterStatus, setFilterStatus] = useState("Pending");
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchBookingHistory = async () => {
      const hostname = window.location.hostname;
      let apiUrl;

      if (
        hostname.includes("testgrouple.com") ||
        hostname.includes("localhost")
      ) {
        apiUrl =
          "https://server.testgrouple.com/api/v1/enduser/profile/getReservationHistory";
      } else if (hostname.includes("grouple.tech")) {
        apiUrl = `https://production.grouple.tech/api/v1/enduser/profile/getReservationHistory`;
      }

      try {
        const response = await axios.post(apiUrl);
        if (response.status === 200) {
          setBookingHistory(response.data.data);
          console.log("data", response);
        } else {
          setError(`Unexpected response code: ${response.status}`);
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching booking history:", error);
      }
    };

    fetchBookingHistory();
  }, []);

  const filteredBookings = (() => {
    const seenReservationCodes = new Set();

    return bookingHistory.filter((booking) => {
      if (seenReservationCodes.has(booking.reservation_code)) {
        return false;
      }

      const shouldInclude =
        (filterStatus === "Confirmed" &&
          (booking.record_status === 1 || booking.record_status === 5)) ||
        (filterStatus === "Pending" && booking.record_status === 0) ||
        (filterStatus === "Cancelled" && booking.record_status === 2);

      if (shouldInclude) {
        seenReservationCodes.add(booking.reservation_code);
      }

      return shouldInclude;
    });
  })();

  const handleFilterChange = (status) => {
    setFilterStatus(status);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hoursInt = parseInt(hours);
    const minutesInt = parseInt(minutes);
    const period = hoursInt >= 12 ? "PM" : "AM";
    const formattedHours = hoursInt % 12 || 12;
    return `${formattedHours}:${
      minutesInt < 10 ? "0" : ""
    }${minutesInt} ${period}`;
  };

  // const getStatus = (status) => {
  //   switch (status) {
  //     case 0:
  //       return { text: "Pending", className: "text-yellow-500" };
  //     case 1:
  //       return { text: "Confirmed", className: "text-green-500" };
  //     case 2:
  //       return { text: "Cancelled", className: "text-red-500" };
  //     default:
  //       return { text: "Unknown", className: "text-gray-500" };
  //   }
  // };
  // const [bookingData, setbookingData] = useState(null);
  // const navbarColor = bookingData?.data?.navbar_color;
  return (
    <div>
      <NewNavbar />
      {/* {windowWidth >= 1024 ? <Navbar4 /> : <Navbar2 />} */}
      <div className="BookingHistoryWrapper">
        <h1 className="BookingsHeading">Bookings</h1>
        <div className="UpcomingResWrapper">
          <div className="upcomingButtons">
            <button
              className={`${
                filterStatus === "Pending" ? "bg-groupleTheme text-white" : ""
              }`}
              onClick={() => handleFilterChange("Pending")}
            >
              Pending
            </button>
            <button
              className={`${
                filterStatus === "Confirmed" ? "bg-groupleTheme text-white" : ""
              }`}
              onClick={() => handleFilterChange("Confirmed")}
            >
              Confirmed
            </button>

            <button
              className={`${
                filterStatus === "Cancelled" ? "bg-groupleTheme text-white" : ""
              }`}
              onClick={() => handleFilterChange("Cancelled")}
            >
              Cancelled
            </button>
          </div>

          {/* web square */}
          {filteredBookings.length > 0 ? (
            filteredBookings.map((reservation, index) => (
              <div className="UpcomingResChild" key={index}>
                <img className="UpcomingresImgOne" src={rectangle} alt="" />
                <div className="upcomingResInner">
                  <div className="UpcomingSummaryWrapper">
                    <div className="UpcomingSummaryChild ">
                      <div
                        className="UpcomingSumaryAdress"
                        style={{ alignItems: "flex-start" }}
                      >
                        <div className="text-neutral-800 text-lg font-normal font-['Gilroy-SemiBold'] outletName">
                          {reservation.bookingOutlet}
                        </div>
                        <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] outletAddress pt-3">
                          {reservation.bookingOutletAddress}
                        </div>
                      </div>
                    </div>
                    <div className="justify-start items-center gap-[1.3rem] inline-flex pt-3">
                      <div className="text-neutral-600 text-[1rem] font-normal font-['Gilroy-Medium'] resDate">
                        {new Date(reservation.bookingDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </div>
                      <div className="w-2 h-2 bg-neutral-700 rounded-full" />
                      <div className="text-neutral-600 text-[1rem] font-normal font-['Gilroy-Medium'] resTime">
                        {formatTime(reservation.bookingSlotStartTime)} -{" "}
                        {formatTime(reservation.bookingSlotEndTime)}
                      </div>
                    </div>
                    <div className="text-neutral-600 text-[1rem] font-normal font-['Gilroy-Medium'] outletAddress pt-2">
                      <span className="outerHead">Guests:</span>{" "}
                      {reservation.bookingTotalGuest}
                    </div>
                    <div className="text-[#4A6AFE] text-[1rem] font-normal font-['Gilroy-Medium'] outletAddress pt-2">
                      <span className="outerHead">Reservation ID:</span> #
                      {reservation.reservation_code}
                    </div>
                  </div>

                  <div className="flex gap-4 mobilebttn">
                    <button className="invoicebttn">View Invoice</button>
                    <button
                      className="detailsbttn"
                      onClick={() =>
                        window.open(
                          `/groupReservation/7gK1Yn8pQF/${reservation.randomReservationCode}`,
                          "_blank"
                        )
                      }
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <img className="placeImg" src={placeholder} alt="" />
          )}

          {/* mobile square */}
          {filteredBookings.length > 0 ? (
            filteredBookings.map((reservation, index) => (
              <div className="UpcomingResChildPhone" key={index}>
                <div className="upperMobileSummary">
                  <img className="UpcomingresImgOne" src={rectangle} alt="" />
                  <div>
                    <div className="text-neutral-800 text-lg font-normal font-['Gilroy-SemiBold'] outletName">
                      {reservation.bookingOutlet}
                    </div>
                    <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] outletAddress">
                      {reservation.bookingOutletAddress}
                    </div>
                  </div>
                </div>

                <div className="upcomingResInner">
                  <div className="UpcomingSummaryWrapper">
                    <div className="justify-start items-center gap-[1rem] inline-flex border-b border-[#D8D8DC] w-full pb-3">
                      <div className="text-neutral-600 text-[1rem] font-normal font-['Gilroy-Medium'] resDate">
                        {new Date(reservation.bookingDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </div>
                      <div className="w-1 h-1 bg-neutral-700 rounded-full" />
                      <div className="text-neutral-600 text-[1rem] font-normal font-['Gilroy-Medium'] resTime">
                        {formatTime(reservation.bookingSlotStartTime)} -{" "}
                        {formatTime(reservation.bookingSlotEndTime)}
                      </div>
                    </div>

                    <div className="!text-[#545456]  outletAddress pt-2">
                      <span className="outerHead">Guests:</span>{" "}
                      {reservation.bookingTotalGuest}
                    </div>
                    <div className="!text-[#4A6AFE] outletAddress pt-2 border-b border-[#D8D8DC] w-full pb-3">
                      <span className="outerHead">Reservation ID:</span> #
                      {reservation.reservation_code}
                    </div>
                  </div>

                  <div className="flex gap-4 mobilebttn">
                    <button className="invoicebttn">View Invoice</button>
                    <button
                      onClick={() =>
                        window.open(
                          `/groupReservation/7gK1Yn8pQF/${reservation.reservation_code}`,
                          "_blank"
                        )
                      }
                      className="detailsbttn"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <img className="placeImgMobile" src={placeholder} alt="" />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingHistory;

{
  /* <div className="PastResWrapper">
          <div className="flex justify-between pr-4">
            <h1 className="PastResHeading">Past Reservation</h1>
            {filteredBookings.length > 5 && (
              <Link to="/pastReservation" className="text-groupleTheme">
                See all
              </Link>
            )}
          </div>

          {filteredBookings.length > 0 ? (
            filteredBookings.map((reservation, index) => (
              <div className="UpcomingResChild" key={index}>
                <img className="UpcomingresImgOne" src={rectangle} alt="" />
                <div className="upcomingResInner">
                  <div className="UpcomingSummaryWrapper">
                    <div className="UpcomingSummaryChild">
                      <div
                        className="UpcomingSumaryAdress"
                        style={{ alignItems: "flex-start" }}
                      >
                        <div className="text-neutral-800 text-lg font-normal font-['Gilroy-SemiBold'] outletName">
                          {reservation.bookingOutlet}
                        </div>
                        <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] outletAddress">
                          {reservation.bookingOutletAddress}
                        </div>
                      </div>
                    </div>
                    <div className="justify-start items-center gap-[22px] inline-flex">
                      <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] resDate">
                        {new Date(reservation.bookingDate).toLocaleDateString()}
                      </div>
                      <div className="w-2 h-2 bg-neutral-700 rounded-full" />
                      <div className="text-neutral-600 text-base font-normal font-['Gilroy-Medium'] resTime">
                        {formatTime(reservation.bookingSlotStartTime)} -{" "}
                        {formatTime(reservation.bookingSlotEndTime)}
                      </div>
                    </div>
                    <div
                      className={`${
                        getStatus(reservation.record_status).className
                      } text-base font-normal font-['Gilroy-SemiBold'] resStatus`}
                    >
                      {getStatus(reservation.record_status).text}
                    </div>
                  </div>
                  <div className="text-groupleTheme text-[22px] font-normal font-['Gilroy-SemiBold'] resCode">
                    #{reservation.reservation_code}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No past reservations.</p>
          )}
        </div> */
}
