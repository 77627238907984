import React, { useState, useEffect, useRef, Suspense } from "react";
import Footer from "./Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../Styles/Switch.css";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Navbar4 } from "./NavbarFour";

const SwitchOutlet = () => {
  const directionsRef = useRef(null);
  const { outlet } = useParams();
  const navigate = useNavigate();
  const [outlets, setOutlets] = useState([]);
  const [activeMap, setActiveMap] = useState("Moksha");
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [imagesLoadedCount, setImagesLoadedCount] = useState(0);
  const [mapsLoadedCount, setMapsLoadedCount] = useState(0);

  const fetchOutlets = async () => {
    const hostname = window.location.hostname;
    setLoading(true);
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/estate/getEstateListByCmpBrandName/${outlet}`;
    } else if (hostname.includes("grouple.tech")) {
      apiUrl = `https://production.grouple.tech/api/v1/enduser/estate/getEstateListByCmpBrandName/${outlet}`;
    }

    try {
      const response = await axios.get(apiUrl);
      if (
        response.status === 200 &&
        response.data?.data === "Invalid company brand name"
      ) {
        navigate("/not-found");
        return;
      }
      if (response.status === 200 && response.data?.data) {
        setOutlets(response.data.data);
      } else {
        throw new Error("Failed to fetch outlet information");
      }
    } catch (error) {
      console.error("Error fetching outlets:", error);
      navigate("/not-found"); // Fallback in case of unexpected errors
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (outlet) {
      fetchOutlets();
    }
  }, [outlet]);

  useEffect(() => {
    if (outlet) {
      fetchOutlets();
    }
  }, [outlet]);

  useEffect(() => {
    fetchOutlets();
  }, []);

  const handleImageLoad = () => {
    setLoading(true);
    setImagesLoadedCount((prevCount) => prevCount + 1);
    setLoading(false);
  };

  const handleMapLoad = () => {
    setLoading(true);
    setMapsLoadedCount((prevCount) => prevCount + 1);
    setLoading(false);
  };

  useEffect(() => {
    if (imagesLoadedCount === outlets.length) {
      setImagesLoaded(true);
    }
  }, [imagesLoadedCount, outlets.length]);

  useEffect(() => {
    if (mapsLoadedCount === outlets.length) {
      setMapLoaded(true);
    }
  }, [mapsLoadedCount, outlets.length]);
  useEffect(() => {
    if (imagesLoaded && mapLoaded) {
      setLoading(false);
    }
  }, [imagesLoaded, mapLoaded]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [hoverMap, setHoverMap] = useState(null);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOutlets = outlets
    .filter((outlet) =>
      outlet.estate_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a.grade && b.grade) {
        return a.grade - b.grade;
      }
      return 0;
    });

  useEffect(() => {
    if (filteredOutlets.length > 0) {
      setActiveMap(filteredOutlets[0].estate_name);
    }
  }, [filteredOutlets]);

  const displayedMap = hoverMap || activeMap;

  // const newOutlet = "/Imagess/Calico/newCalico.png";

  // const librarycrad = "/Imagess/Library/libraryCard.png"

  const location = "/Imagess/Calico/locationCalico.png";

  const location2 = "../../public/Imagess/Glassroom/locationGlassroom.png";
  const locatiom3 = "../../public/Imagess/basement/locationBasement.png";

  return (
    <>
      <Navbar4 cmpcode={outlets[0]?.cmpcode} outlet={outlet} />
      {loading || !(imagesLoaded && mapLoaded) ? (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
        </div>
      ) : (
        <div className="switchParent">
          {/* Left side outlets list */}
          <div className="leftSwitch">
            {/* Search button */}
            <div className="searchButton">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative text-gray-400 focus-within:text-gray-300">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  className="block w-full rounded-md border border-gray-800 py-2.5 pl-10 pr-3 text-gray-900 sm:text-sm sm:leading-6 focus:border-none focus:color-white"
                  placeholder="Search venues"
                  type="search"
                  name="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>

            <div className="outletsMultiple">
              {filteredOutlets.length > 0 ? (
                filteredOutlets.map((outlet, index) =>
                  outlet.is_clickable === 1 ? (
                    <Link
                      to={`/package/${outlet.estate_name}/${outlet.cmpcode}/${outlet.estatecode}`}
                      key={index}
                      style={{ textDecoration: "none" }}
                      onMouseEnter={() => setHoverMap(outlet.estate_name)}
                      onMouseLeave={() => setHoverMap(null)}
                    >
                      <div>
                        <Suspense
                          fallback={
                            <div className="flex items-center justify-center h-screen">
                              <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
                            </div>
                          }
                        >
                          <img
                            src={outlet.logo}
                            alt={outlet.estate_name}
                            onLoad={handleImageLoad}
                          />
                        </Suspense>
                        <h2>{outlet.estate_name}</h2>
                        <h3>
                          {outlet?.min_capacity} - {outlet?.floating_capacity}{" "}
                          Guests
                        </h3>
                        <h4>{outlet.address}</h4>
                      </div>
                    </Link>
                  ) : (
                    <div
                      key={index}
                      style={{ textDecoration: "none", cursor: "not-allowed" }}
                    >
                      <div>
                        <Suspense
                          fallback={
                            <div className="flex items-center justify-center h-screen">
                              <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
                            </div>
                          }
                        >
                          <img
                            src={outlet.logo}
                            alt={outlet.estate_name}
                            onLoad={handleImageLoad}
                            style={{ opacity: 0.5 }}
                          />
                        </Suspense>
                        <h2>{outlet.estate_name}</h2>
                        <h3>
                          {outlet?.min_capacity} - {outlet?.max_capacity} Guests
                        </h3>
                        <h4>{outlet.address}</h4>
                      </div>
                    </div>
                  )
                )
              ) : (
                <p>No outlets available</p>
              )}
            </div>
          </div>

          {/* Right side map */}
          <div className="rightSwitch">
            <div ref={directionsRef} id="directions" className="sticky-right">
              {outlets.length > 0 && outlets[0].google_map_description ? (
                <iframe
                  title="Google Maps"
                  src={outlets[0].google_map_description}
                  width="100%"
                  height="600"
                  style={{ border: "0px", borderRadius: "8px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              ) : (
                <p>Google Maps link not available</p>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <div className={isMobile ? "footer-hidden" : ""}> */}
      <Footer />
      {/* </div> */}
    </>
  );
};

export default SwitchOutlet;
