import React, { useState, useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../Styles/PackageInfo.css";
import header from "../Images/header2.png";
import "../Styles/FoodMenu.css";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";
import axios from "axios";
import { Navbar4 } from "./NavbarFour";
import LoadingAnimation from "../Images/LOADINGaNIMATION.gif";

const PackageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
      fill="#4A6AFE"
    />
    <path
      d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
      fill="#DBE1FF"
    />
  </svg>
);

const BestValueIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <path
      d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
      fill="#EE4622"
      stroke="#EE4622"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
  </svg>
);

const DurationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PeopleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
      stroke="#8E8E93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NonVegIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#963415" />
    <path
      d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
      fill="#963415"
    />
  </svg>
);

const VegsIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#009600" />
    <circle cx="8" cy="8" r="4" fill="#009600" />
  </svg>
);

const AddOn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const edit = searchParams.get("edit");
  const [loading, setLoading] = useState(true);
  const { outlet, cmpcode, estatecode, package_code } = useParams();
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [itemDetails, setItemDetails] = useState([]);
  const [packageCategoriesAddOn, setPackageCategoriesAddOn] = useState([]);
  const [data2, setData2] = useState({});
  const [buttonFoodColor, setButtonFoodColor] = useState("");
  const [buttonTextFoodColor, setButtonTextFoodColor] = useState("");
  let reservationDataFoodAndBev = location.state?.reservationData || {};

  // console.log(reservationDataFoodAndBev)
  const fetchFoodAndBeverageforAddon = async () => {
    const hostname = window.location.hostname;
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl =
        "https://server.testgrouple.com/api/v1/enduser/addon/getAddonDetails";
    } else if (hostname.includes("grouple.tech")) {
      apiUrl = `https://production.grouple.tech/api/v1/enduser/addon/getAddonDetails`;
    }
    setLoading(true);

    try {
      const response = await axios.post(apiUrl, {
        cmpcode,
        estatecode,
        package_code,
      });

      if (response.status === 200 && response.data && response.data.data) {
        const dataObjectWithItems = response.data.data.find(
          (obj) => obj.itemDetails && obj.packageCategories
        );

        // console.log(response);
        setButtonFoodColor(response.data.data[0].button_color);
        setButtonTextFoodColor(response.data.data[0].button_text_color);
        if (dataObjectWithItems) {
          setItemDetails(dataObjectWithItems.itemDetails);
          setPackageCategoriesAddOn(dataObjectWithItems.packageCategories);
        } else {
          throw new Error(
            "No valid data found for itemDetails or packageCategories"
          );
        }
      } else {
        throw new Error("Failed to fetch addon details");
      }
    } catch (error) {
      console.error("Error fetching addon details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFoodAndBeverageforAddon();
    console.log("itemDetails", itemDetails);
    console.log("packageCategories", packageCategoriesAddOn);
  }, []);

  useEffect(() => {
    if (itemDetails?.length > 0) {
      const data = {};
      packageCategoriesAddOn.forEach((category) => {
        const categoryCode = category.addon_category_code;
        const categoryIndex = itemDetails.findIndex(
          (itemDetail) => itemDetail.addon_category_code === categoryCode
        );
        data[categoryCode] = itemDetails[categoryIndex]?.addon_item_data || [];
      });

      setData2(data);

      setSelectedItemsAddOns((prevSelectedItems) => {
        const updatedSelectedItemsAddOns = { ...prevSelectedItems };
        sessionStorage.setItem(
          "selectedItemsAddOns",
          JSON.stringify(updatedSelectedItemsAddOns)
        );
        return updatedSelectedItemsAddOns;
      });
    }
  }, [packageCategoriesAddOn, itemDetails]);

  if (Object.keys(reservationDataFoodAndBev).length === 0) {
    const localStorageData = sessionStorage.getItem(
      "reservationDataFoodAndBev"
    );
    if (localStorageData) {
      reservationDataFoodAndBev = JSON.parse(localStorageData);
    }
  }

  const [vegOnly, setVegOnly] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Appetizer");

  const [selectedItemsAddOns, setSelectedItemsAddOns] = useState(() => {
    const storedItems = JSON.parse(
      sessionStorage.getItem("selectedItemsAddOns")
    );

    const initialSelectedItemsAddOns = packageCategoriesAddOn.reduce(
      (acc, category) => {
        const categoryCode = category.fnb_category_code;
        const maxItemsAllowed = category.allowed_nos;

        if (maxItemsAllowed === -1 && data2[categoryCode]) {
          acc[categoryCode] = data2[categoryCode];
        } else {
          acc[categoryCode] = [];
        }
        return acc;
      },
      {}
    );

    return storedItems || initialSelectedItemsAddOns;
  });

  console.log(selectedItemsAddOns);

  useEffect(() => {
    if (edit === "true") {
      const storedItems = JSON.parse(
        sessionStorage.getItem("selectedItemsAddOns")
      );
      if (storedItems) {
        setSelectedItemsAddOns(storedItems);
      }
    }
  }, [edit]);

  const leftContainerRef = useRef(null);

  const getThreshold = () => {
    return window.innerWidth <= 641 ? -100 : -300;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      let foundCategory = null;

      Object.keys(data2).forEach((category) => {
        const categoryElement = document.getElementById(category);
        if (categoryElement) {
          const { offsetTop, offsetHeight } = categoryElement;
          const threshold = getThreshold();

          if (
            scrollPosition >= offsetTop - threshold &&
            scrollPosition < offsetTop + offsetHeight - threshold
          ) {
            foundCategory = category;
          }
        }
      });

      setSelectedCategory(foundCategory);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [data2]);

  const handleCategoryClick = (event, categoryCode) => {
    event.preventDefault();
    setSelectedCategory(categoryCode);
    const categoryElement = document.getElementById(categoryCode);
    if (categoryElement) {
      const { top } = categoryElement.getBoundingClientRect();
      const offset = 100;
      const newPosition = window.scrollY + top - offset;

      window.scrollTo({
        top: newPosition,
        behavior: "smooth",
      });
    } else {
      console.warn(`Element with ID ${categoryCode} not found.`);
    }
  };

  useEffect(() => {
    sessionStorage.setItem(
      "selectedItemsAddOns",
      JSON.stringify(selectedItemsAddOns)
    );
  }, [selectedItemsAddOns]);

  const categoryChoicesCode = packageCategoriesAddOn.reduce((acc, category) => {
    acc[category.fnb_category_code] = category.allowed_nos;
    return acc;
  }, {});

  const categoryChoices = packageCategoriesAddOn.reduce((acc, category) => {
    acc[category.fnb_category] = category.allowed_nos;
    return acc;
  }, {});

  const calculateTotalItems = () => {
    return Object.values(selectedItemsAddOns).reduce((total, categoryItems) => {
      return (
        total + categoryItems.reduce((sum, item) => sum + item.quantity, 0)
      );
    }, 0);
  };

  const calculateTotalPrice = () => {
    return Object.values(selectedItemsAddOns)
      .reduce((total, categoryItems) => {
        return (
          total +
          categoryItems.reduce(
            (sum, item) => sum + item.quantity * item.addon_item_actual_price,
            0
          )
        );
      }, 0)
      .toFixed(2);
  };

  const handleAddRemoveItem = (categoryCode, item, action) => {
    setSelectedItemsAddOns((prevSelectedItems) => {
      const categoryItems = prevSelectedItems[categoryCode] || [];
      const itemIndex = categoryItems.findIndex(
        (selectedItem) => selectedItem.addon_item_code === item.addon_item_code
      );

      let updatedItems;
      if (itemIndex !== -1) {
        const selectedItem = categoryItems[itemIndex];
        let updatedQuantity = selectedItem.quantity || 1;

        if (action === "increment") {
          updatedQuantity += 1;
        } else if (action === "decrement") {
          updatedQuantity -= 1;
        }

        if (updatedQuantity <= 0) {
          updatedItems = [
            ...categoryItems.slice(0, itemIndex),
            ...categoryItems.slice(itemIndex + 1),
          ];
        } else {
          updatedItems = [
            ...categoryItems.slice(0, itemIndex),
            { ...selectedItem, quantity: updatedQuantity },
            ...categoryItems.slice(itemIndex + 1),
          ];
        }
      } else if (action === "increment") {
        updatedItems = [...categoryItems, { ...item, quantity: 1 }];
      } else {
        updatedItems = categoryItems;
      }

      const updatedSelectedItemsAddOns = {
        ...prevSelectedItems,
        [categoryCode]: updatedItems,
      };

      sessionStorage.setItem(
        "selectedItemsAddOns",
        JSON.stringify(updatedSelectedItemsAddOns)
      );
      return updatedSelectedItemsAddOns;
    });
  };

  const renderItems = (items, categoryCode) => {
    const filteredItems = vegOnly
      ? items.filter((item) => item.addon_type !== "NON-VEG")
      : items;

    return filteredItems.map((item, i) => {
      const selectedItem = (selectedItemsAddOns[categoryCode] || []).find(
        (selectedItem) => selectedItem.addon_item_code === item.addon_item_code
      );

      const quantity = selectedItem ? selectedItem.quantity : 0;
      const maxItemsAllowed =
        categoryChoices[categoryCode] !== undefined
          ? categoryChoices[categoryCode]
          : Infinity;

      const canAddMoreItems =
        (selectedItemsAddOns[categoryCode] || []).length < maxItemsAllowed;

      return (
        <div key={i} className="FoodMenu-child">
          <div>
            <div>{item.addon_type === "NON-VEG" ? NonVegIcon : VegsIcon}</div>
            <div className="Food-menu-item-name">{item.addon_item_name}</div>
            <div className="Food-menu-item-desc">
              {item.addon_item_desc.length > 90 &&
              !expandedDescriptions[item.addon_item_code] ? (
                <>
                  {`${item.addon_item_desc.slice(0, 90)}... `}
                  <button
                    className="read-more-button"
                    onClick={() =>
                      setExpandedDescriptions({
                        ...expandedDescriptions,
                        [item.addon_item_code]: true,
                      })
                    }
                  >
                    Read More
                  </button>
                </>
              ) : (
                item.addon_item_desc
              )}
            </div>
            <div className="Food-menu-item-name">
              {reservationDataFoodAndBev?.package_currency}{" "}
              {item.addon_item_actual_price}
            </div>
          </div>
          <div className="food-menu-picture-container">
            <img
              className="food-menu-picture"
              src={item.addon_item_img_path}
              alt=""
            />
            <div className="button-container">
              {quantity > 0 ? (
                <div
                  className=" rounded-lg px-3 flex gap-3 py-1.5"
                  style={{
                    backgroundColor: reservationDataFoodAndBev.buttonColor,
                    color: reservationDataFoodAndBev.buttonTextColor,
                  }}
                >
                  <button
                    className="quantity-button"
                    onClick={() =>
                      handleAddRemoveItem(categoryCode, item, "decrement")
                    }
                  >
                    -
                  </button>
                  <span>{quantity}</span>
                  <button
                    className="quantity-button"
                    onClick={() =>
                      handleAddRemoveItem(categoryCode, item, "increment")
                    }
                  >
                    +
                  </button>
                </div>
              ) : (
                canAddMoreItems && (
                  <button
                    className="button-select"
                    onClick={() =>
                      handleAddRemoveItem(categoryCode, item, "increment")
                    }
                    style={{
                      color: reservationDataFoodAndBev.buttonColor,
                      border: `1px solid ${reservationDataFoodAndBev.buttonColor}`,
                    }}
                  >
                    Add
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderCategoryHeader = (categoryCode) => {
    const categoryNames = packageCategoriesAddOn.reduce((acc, category) => {
      acc[category.addon_category_code] = category.addon_category;
      return acc;
    }, {});

    const categoryName = categoryNames[categoryCode] || categoryCode;
    const selectedCount = (selectedItemsAddOns[categoryCode] ?? []).length;
    const choice = categoryChoices[categoryCode];

    if (choice !== undefined) {
      if (choice === -1) {
        return selectedCount > 0
          ? `${categoryName} (${selectedCount} / Unlimited)`
          : `${categoryName} (Unlimited)`;
      } else {
        return selectedCount > 0
          ? `${categoryName} (${selectedCount} / ${choice})`
          : `${categoryName} (Choose ${choice})`;
      }
    }

    return categoryName;
  };

  const totalAddOnsPrice = calculateTotalPrice();

  const handleContinueClick = () => {
    const loggedIn = document.cookie.includes("loggedIn=true");
    if (!loggedIn) {
      window.location.href = "/signin";
      return;
    }
    const reservationDataFnB = {
      packageName: reservationDataFoodAndBev.packageName,
      duration: reservationDataFoodAndBev.duration,
      initialPrice: reservationDataFoodAndBev.initialPrice,
      initialDiscountedPrice: reservationDataFoodAndBev.initialDiscountedPrice,
      bestValue: reservationDataFoodAndBev.bestValue,
      package_tag: reservationDataFoodAndBev.package_tag,
      date: reservationDataFoodAndBev.date,
      timeSlot: reservationDataFoodAndBev.timeSlot,
      displayTime: reservationDataFoodAndBev.displayTime,
      discountedPrice: reservationDataFoodAndBev.discountedPrice,
      price: reservationDataFoodAndBev.price,
      selectedPeople: reservationDataFoodAndBev.selectedPeople,
      moneyToBeSaved: reservationDataFoodAndBev.moneyToBeSaved,
      displayDate: reservationDataFoodAndBev.displayDate,
      isFndB: reservationDataFoodAndBev?.isFandB,
      packageCusine: reservationDataFoodAndBev.packageCusine,
      selectedItems: reservationDataFoodAndBev.selectedItems,
      selectedItemsAddOns,
      package_currency: reservationDataFoodAndBev.package_currency,
      pax_min: reservationDataFoodAndBev?.pax_min,
      pax_max: reservationDataFoodAndBev?.pax_max,
      packageCategories: reservationDataFoodAndBev?.packageCategories,
      packageCategoriesAddOn,
      Outlet_address: reservationDataFoodAndBev.Outlet_address,
      package_category: reservationDataFoodAndBev.package_category,
      ServiceFee: reservationDataFoodAndBev.ServiceFee,
      buttonColor: reservationDataFoodAndBev.buttonColor,
      buttonTextColor: reservationDataFoodAndBev.buttonTextColor,
      totalAddOnsPrice,
    };
    sessionStorage.setItem(
      "reservationDataFnB",
      JSON.stringify(reservationDataFnB)
    );
    navigate(
      `/groupSummary/${outlet}/${cmpcode}/${estatecode}/${package_code}`,
      {
        state: { reservationDataFnB },
      }
    );
  };

  const estateCode = localStorage.getItem("estateCode");
  setEstateCodeForTheme(estateCode);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);

    if (!isDropdownOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <img src={LoadingAnimation} alt="Loading..." />
      </p>
    );
  }
  return (
    <>
      <Navbar4 />
      <div
        style={{
          position: "sticky",
        }}
        className="PackageInfoMain"
      >
        <div className="PackageInfoInnerLeft">
          <img className="headerImgPackage" src={header} alt="foodImage" />
          <div className="packageLeftOne">
            <div className="packageNameDiv">
              <div>
                {PackageIcon}
                {reservationDataFoodAndBev?.package_tag}
              </div>
              {reservationDataFoodAndBev?.bestValue && (
                <div>
                  {BestValueIcon}
                  BEST VALUE
                </div>
              )}
            </div>
            <h1>{reservationDataFoodAndBev?.packageName}</h1>
            <p>{reservationDataFoodAndBev?.packageCusine}</p>
            <div className="inside-nonAlcoholic">
              <div
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                {DurationIcon}
                {reservationDataFoodAndBev.displayTime}
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                {PeopleIcon}
                {reservationDataFoodAndBev.selectedPeople} Guests
              </div>
            </div>
          </div>

          <div className="packageLeftTwo">
            <div className="foodMenu-container">
              <div className="left-container" ref={leftContainerRef}>
                <ul>
                  <li>
                    <strong className="strong">Extras</strong>
                  </li>
                  {packageCategoriesAddOn.map((category, i) => (
                    <li key={i}>
                      <a
                        href={`#${category.addon_category_code}`}
                        onClick={(event) =>
                          handleCategoryClick(
                            event,
                            category.addon_category_code
                          )
                        }
                        className={`category ${
                          selectedCategory === category.addon_category_code
                            ? "active"
                            : ""
                        } ${
                          category === "Food menu" ? "food-menu-category" : ""
                        } ${
                          category === "Beverage menu"
                            ? "beverage-menu-category-res"
                            : ""
                        }`}
                      >
                        {category.addon_category}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="right-container">
                {Object.keys(data2).map(
                  (categoryCode, index) =>
                    data2[categoryCode]?.length > 0 && (
                      <section key={index} id={categoryCode}>
                        <div className="max-width">
                          <div className="FoodCategoryHeading">
                            <h2 id={categoryCode}>
                              {renderCategoryHeader(categoryCode)}
                            </h2>

                            {index === 0 && (
                              <div className="vegOnlyToggleDiv">
                                <span>Veg only</span>
                                <div className="toggle-wrapper">
                                  <input
                                    className="toggle-checkbox"
                                    type="checkbox"
                                    checked={vegOnly}
                                    onChange={() => setVegOnly(!vegOnly)}
                                  />
                                  <div className="toggle-container">
                                    <div className="toggle-button">
                                      <div className="toggle-button-circles-container"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              categoryCode === "spiritsCode" ? "aaaa" : "bbbb"
                            }
                          >
                            {Array.isArray(data2[categoryCode]) ? (
                              renderItems(data2[categoryCode], categoryCode)
                            ) : (
                              <div className="food-menu-wrapper-parent">
                                {Object.keys(data2[categoryCode]).map(
                                  (subCategory, subIndex) => (
                                    <div key={subIndex}>
                                      <h1 className="FoodCategoryHeading">
                                        {subCategory}
                                      </h1>
                                      <div className="food-menu-wrapper-child">
                                        {renderItems(
                                          data2[categoryCode][subCategory],
                                          categoryCode
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )
                )}

                {/* {/ footer /} */}
                <div className="PackageInfofooter2 relative">
                  <div className="w-full flex justify-between items-center">
                    <div className="continue-container-01">
                      <div className="flex gap-2 items-center">
                        <div className="relative inline-block w-8 h-8">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M3.33203 11.332H28.6654L25.8654 28.6654H6.13203L3.33203 11.332Z"
                              stroke="black"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M23.3346 11.332C23.3346 6.91336 20.0506 3.33203 16.0013 3.33203C11.9506 3.33203 8.66797 6.91336 8.66797 11.332"
                              stroke="black"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3333 18.6667C11.687 18.6667 12.0261 18.5262 12.2761 18.2761C12.5262 18.0261 12.6667 17.687 12.6667 17.3333C12.6667 16.9797 12.5262 16.6406 12.2761 16.3905C12.0261 16.1405 11.687 16 11.3333 16C10.9797 16 10.6406 16.1405 10.3905 16.3905C10.1405 16.6406 10 16.9797 10 17.3333C10 17.687 10.1405 18.0261 10.3905 18.2761C10.6406 18.5262 10.9797 18.6667 11.3333 18.6667Z"
                              fill="black"
                            />
                            <path
                              d="M12 22C12 22 13.3333 24 16 24C18.6667 24 20 22 20 22"
                              stroke="black"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.6654 18.6667C21.019 18.6667 21.3581 18.5262 21.6082 18.2761C21.8582 18.0261 21.9987 17.687 21.9987 17.3333C21.9987 16.9797 21.8582 16.6406 21.6082 16.3905C21.3581 16.1405 21.019 16 20.6654 16C20.3117 16 19.9726 16.1405 19.7226 16.3905C19.4725 16.6406 19.332 16.9797 19.332 17.3333C19.332 17.687 19.4725 18.0261 19.7226 18.2761C19.9726 18.5262 20.3117 18.6667 20.6654 18.6667Z"
                              fill="black"
                            />
                          </svg>
                          {calculateTotalItems() > 0 && (
                            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">
                              {calculateTotalItems()}
                            </span>
                          )}
                        </div>

                        <div className="">{totalAddOnsPrice}</div>

                        <div
                          className="arrowForAddon cursor-pointer"
                          onClick={toggleDropdown}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className={`transition-transform duration-300 ${
                              isDropdownOpen ? "rotate-180" : ""
                            }`}
                          >
                            <path
                              d="M15 12.5L10 7.5L5 12.5"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>

                      {isDropdownOpen && (
                        <>
                          <div className="overlay fixed inset-0 bg-black opacity-50 z-40"></div>

                          <div className="addonDropdown-parent absolute bottom-16 left-4  bg-white border border-gray-300 z-50 p-4">
                            <div
                              onClick={toggleDropdown}
                              className="flex w-full justify-end pb-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                              >
                                <path
                                  d="M7 7.5L17 17.5"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7 17.5L17 7.5"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="dropdown-content">
                              {Object.keys(selectedItemsAddOns).length === 0 ? (
                                <div>No items added yet</div>
                              ) : (
                                Object.keys(selectedItemsAddOns).map(
                                  (categoryCode) =>
                                    selectedItemsAddOns[categoryCode].length >
                                    0 ? (
                                      selectedItemsAddOns[categoryCode].map(
                                        (item, idx) => (
                                          <div
                                            key={idx}
                                            className="py-2 border-b sm:flex justify-between items-center flex gap-4 text-[1rem]"
                                          >
                                            <div className="flex flex-col items-start gap-2">
                                              <div>
                                                {item.addon_type === "NON-VEG"
                                                  ? NonVegIcon
                                                  : VegsIcon}
                                              </div>
                                              {item.addon_item_name}
                                            </div>
                                            <div className="w-[40%]">
                                              {
                                                reservationDataFoodAndBev?.package_currency
                                              }{" "}
                                              {item.addon_item_actual_price} x{" "}
                                              {item.quantity}
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div>No items added yet</div>
                                    )
                                )
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <button
                      className="AddOnContinue"
                      onClick={handleContinueClick}
                      style={{
                        backgroundColor: reservationDataFoodAndBev.buttonColor,
                        color: reservationDataFoodAndBev.buttonTextColor,
                      }}
                    >
                      View order
                    </button>
                  </div>
                </div>
              </div>
               
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOn;
