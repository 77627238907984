import React, { useState, useEffect } from "react";
import logo from "../Images/grouplelogo.png";
import "../Styles/SignIn.css";
import axios from "axios";
import { useReferrer } from "./RefrrerContext";
import { useNavigate } from "react-router-dom";

export default function Create() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    date: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const { referrer } = useReferrer();
  const navigate = useNavigate();
  useEffect(() => {
    const { firstName, lastName, email, date } = formData;
    setIsFormValid(firstName && lastName && email && date);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    const hostname = window.location.hostname;
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl =
        "https://server.testgrouple.com/api/v1/enduser/profile/createProfile";
    } else if (hostname.includes("grouple.tech")) {
      apiUrl = `https://production.grouple.tech/api/v1/enduser/profile/createProfile`;
    }

    const payload = {
      customer_fname: formData.firstName,
      customer_lname: formData.lastName,
      customer_email: formData.email,
      customer_dob: formData.date,
    };

    try {
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
      });
      // console.log("Response:", response);
      window.location.href = referrer;
      // navigate("/location")
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const buttonStyle = {
    background: isFormValid ? "#4a6afe" : "#d8d8dc",
  };

  return (
    <>
      <div className="bg-[#F8F8FF] flex min-h-[100vh] flex-1 flex-col justify-center py-8">
        <div>
          <img className="mx-auto h-14 w-auto" src={logo} alt="grouple-logo" />
        </div>

        <div className="mt-8 sm:mx-auto">
          <div className="signInCard2">
            <h2 className="signInText">Create account</h2>

            <form method="POST" onSubmit={handleSubmit}>
              {/* first name */}
              <div>
                <label htmlFor="firstName" className="enterText mt-3">
                  First name*
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="text"
                    placeholder="First name"
                    required
                    className="nameInput"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* last name */}
              <div>
                <label htmlFor="lastName" className="enterText mt-3">
                  Last name*
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="text"
                    placeholder="Last name"
                    required
                    className="nameInput"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* email */}
              <div>
                <label htmlFor="email" className="enterText mt-3">
                  Enter your name*
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    required
                    className="nameInput"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Date of birth */}
              <div>
                <label htmlFor="date" className="enterText mt-3">
                  Date of birth
                </label>
                <div className="mt-2">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    autoComplete="date"
                    placeholder="dd/mm/yy"
                    required
                    className="dateInput"
                    value={formData.date}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* submit button */}
              <div>
                <button
                  type="submit"
                  className="contBttnSignIn"
                  style={buttonStyle}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
