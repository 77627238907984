import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/Navbar.css";

const Navbar = () => {
  const [companyLogo, setCompanyLogo] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    setTimeout(() => {
      const compData = JSON.parse(localStorage.getItem("companyData"));
      const backgroundColor =
        compData && compData[0].company_name === "Hotel1843"
          ? "#1E5A53"
          : "#fff";
      const logo = compData ? compData[0].compnay_logo : "";
      setCompanyLogo(logo);
      setBackgroundColor(backgroundColor);
      setLoading(false);
    }, 800);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-primary h-12 w-12"></div>
    </div>
  ) : (
    <nav className="navbar-div" style={{ backgroundColor }}>
      <Link to={`/location/${localStorage.getItem("outlet") || ""}`}>
        <img src={companyLogo} alt="Company Logo" className="barbaar-logo1" />
      </Link>
      <div className="buttondivnav">
        <Link
          to={`/location/${localStorage.getItem("outlet") || ""}`}
          style={{ textDecoration: "none" }}
        >
          <button className="reserveNow">Reserve Now</button>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
