import React from "react";
import page from "../Images/Page not found.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          height: "89vh",
          margin: "0 auto",
          justifyContent: "center",
        }}
      >
        <img src={page} alt="" />
      </div>
      <Link
        to={`/location/${localStorage.getItem("outlet") || ""}`}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <button
          style={{
            display: "flex",
            margin: "0 auto",
            padding: "0.8125rem 9.75rem",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.625rem",
            borderRadius: "0.5rem",
            background: "#4A6AFE",
            color: "#FFF",
            fontFamily: "Gilroy-SemiBold",
            fontSize: "1.125rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.0225rem",
          }}
        >
          Go to Home
        </button>{" "}
      </Link>
    </>
  );
};

export default NotFound;
